import axios from 'axios'

async function changeOpenTextField(
  orderFormId: string,
  openTextField: {
    value: string
  }
) {
  try {
    await axios
      .post('/api/setOpentextField', { orderFormId, openTextField })
      .then((response) => {
        return response.data
      })
  } catch (error) {
    console.error(error)
  }
}

export default changeOpenTextField
