import React, { useEffect } from 'react'
import axios from 'axios'
import { set, get } from 'idb-keyval'
import { useCart } from 'src/sdk/cart'
import { getCookie } from 'src/utils/cookie'

const UtmCampaignCart = () => {
  const { id } = useCart()

  useEffect(() => {
    async function createOrderform() {
      const idFsCart = navigator?.cookieEnabled && (await get('fs::cart'))

      if (idFsCart?.id === '' && navigator?.cookieEnabled) {
        const { data: newOrderFormId } = await axios.get('/api/getOrderFormId')

        await set('fs::cart', {
          id: newOrderFormId,
          items: [],
          messages: [],
        }).then(() => {
          window.location.reload()
        })
      }
    }

    const utmCampaign = getCookie('utm_vivara')

    if (utmCampaign) {
      createOrderform()
    }
  }, [id])

  return <div data-active-utmCampaign />
}

export default UtmCampaignCart
