import type { PromotionProps, PromotionProduct } from './PromotionBar'
import { isGlobalPromotion } from './ValidatePromotionOnCart'

export const processCategoriesIds = (
  categoriesIds: Array<string | null> | null
): string[] => {
  if (!categoriesIds) {
    return []
  }

  const categoryArrays = categoriesIds.map((category) =>
    category ? category.split('/') : []
  )

  const nonEmptyCategoryArrays = categoryArrays.map((category) =>
    category?.filter(Boolean)
  )

  const flattenedCategories = nonEmptyCategoryArrays.flat()

  return [...new Set(flattenedCategories)]
}

const validateCategoryPage = (
  promotion: PromotionProps,
  itemData: PromotionProduct
) => {
  const productCategoriesArray = processCategoriesIds(itemData.categoriesIds)

  const promoCategories = promotion.categories.map((p) => p.id)
  const hasFindCategory = productCategoriesArray.some((p) =>
    promoCategories.includes(p)
  )

  if (promotion.categories.length === 0) {
    return true
  }

  return hasFindCategory
    ? promotion.categoriesAreInclusive
    : promotion.categoriesAreInclusive === false
}

const validateSkuPage = (
  promotion: PromotionProps,
  itemData: PromotionProduct
) => {
  const hasFindSku = promotion.skus.some((p) => p.id === itemData.sku)

  if (promotion.skus.length === 0) {
    return true
  }

  return hasFindSku
    ? promotion.skusAreInclusive
    : promotion.skusAreInclusive === false
}

const validateBrandPage = (
  promotion: PromotionProps,
  itemData: PromotionProduct
) => {
  const hasFindBrand = promotion.brands.some(
    (p) => p.id === itemData?.brandId?.toString()
  )

  if (promotion.brands.length === 0) {
    return true
  }

  return hasFindBrand
    ? promotion.brandsAreInclusive
    : promotion.brandsAreInclusive === false
}

const validateCollectionPage = (
  promotion: PromotionProps,
  itemData: PromotionProduct
) => {
  const clusterCollections = itemData.productClusters?.map(
    (productCluster) => productCluster?.id ?? ''
  )

  const productCollectionsArray = clusterCollections ?? []

  const promoCollections = promotion.collections.map((p) => p.id)
  const hasFindCollections = productCollectionsArray.some((p) =>
    promoCollections.includes(p)
  )

  if (promotion.collections.length === 0) {
    return true
  }

  return hasFindCollections
    ? promotion.collectionsIsInclusive
    : promotion.collectionsIsInclusive === false
}

const validateProductPage = (
  promotion: PromotionProps,
  itemData: PromotionProduct
) => {
  const hasFindProduct = promotion.products.some(
    (p) => p.id === itemData.productId
  )

  if (promotion.products.length === 0) {
    return true
  }

  return hasFindProduct
    ? promotion.productsAreInclusive
    : promotion.productsAreInclusive === false
}

export const viewPromotionOnPage = (
  promoData: PromotionProps,
  productData: PromotionProduct
) => {
  if (
    promoData.utmCampaign !== undefined ||
    promoData.totalValueFloor === 0 ||
    promoData.isActive === false ||
    promoData.paymentsMethods.length > 0 ||
    promoData.marketingTags.length > 0 ||
    new Date() > new Date(promoData.endDateUtc) ||
    promoData.zipCodeRanges.length > 0 ||
    promoData.totalValuePurchase !== 0 ||
    promoData.minInstallment !== 0 ||
    promoData.maxInstallment !== 0
  ) {
    return false
  }

  if (isGlobalPromotion(promoData)) {
    return true
  }

  return (
    validateCategoryPage(promoData, productData) &&
    validateSkuPage(promoData, productData) &&
    validateProductPage(promoData, productData) &&
    validateCollectionPage(promoData, productData) &&
    validateBrandPage(promoData, productData)
  )
}
