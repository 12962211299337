import type { MessageProps } from './PromotionBar'

export const orderPromotionData = (promotionData: MessageProps[]) => {
  return promotionData.sort((a, b) => a.floorPrice - b.floorPrice)
}

export const filterPromotionByCartItemPrice = (
  promotionData: MessageProps[]
) => {
  const filteredPromotion = promotionData.filter((p) => p.cartItemPrice !== 0)

  return filteredPromotion.length > 0 ? filteredPromotion : promotionData
}

export const filterPromotionByVisiblePromo = (
  promotionData: MessageProps[]
) => {
  return promotionData.filter((p) => p.visiblePromo)
}

export const calculateMissingPrice = (promotionData: MessageProps[]) => {
  const sortByPriceStatus = promotionData
    .filter((p) => p?.priceStatus)
    .map((p) => p.floorPrice)

  const sortByNotPriceStatus = promotionData
    .filter((p) => !p?.priceStatus)
    .map((p) => p.cartItemPrice)

  const priceMessage = Math.max(...sortByPriceStatus)

  return Math.max(...sortByNotPriceStatus) >= priceMessage
    ? Math.max(...sortByNotPriceStatus)
    : priceMessage
}

export const getMaxPromoPrice = (promotionData: MessageProps[]) => {
  const filteredPrices = promotionData
    .filter((p) => p.visiblePromo)
    .map((p) => p.floorPrice)

  return filteredPrices.length ? Math.max(...filteredPrices) : 0
}
