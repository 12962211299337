import React from 'react'
import type { ReactNode } from 'react'

interface ClientOnlyProps {
  children: ReactNode
}

function ClientOnly({ children }: ClientOnlyProps) {
  const [hasMounted, setHasMounted] = React.useState(false)

  React.useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return <></>
  }

  return <>{children}</>
}

export default ClientOnly
