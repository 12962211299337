import type { FC } from 'react'
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useMemo,
} from 'react'

interface CampaignProviderProps {
  children?: React.ReactNode
}

const CampaignContext = createContext<campaignContextProps>(
  {} as campaignContextProps
)

export const CampaignProvider: FC<CampaignProviderProps> = ({ children }) => {
  const [campaignPage, setCampaignPage] = useState(false)

  useEffect(() => {
    const localTheme = sessionStorage?.getItem('campaignTheme')

    localTheme && setCampaignPage(localTheme === 'true')
  }, [])

  const value = useMemo(
    () => ({
      campaignPage,
      setCampaignPage,
    }),
    [campaignPage]
  )

  return (
    <CampaignContext.Provider value={value}>
      {children}
    </CampaignContext.Provider>
  )
}

export const useCampaignContext = () => {
  return useContext(CampaignContext)
}
