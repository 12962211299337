import React from 'react'

interface BarStatusProps {
  appliedPromotion: number
  promoDiscounts: [
    {
      quantity: number
      percent: number
    }
  ]
}

interface PromotionDiscounts {
  quantity: number
  percent: number
}

function setVisibleSteps(promotionArray: PromotionDiscounts[]) {
  return promotionArray?.map((promotion) => ({
    ...promotion,
    visible: true,
  }))
}

function createNewPromotionsSteps(promotionArray: PromotionDiscounts[]) {
  const visibleSteps = setVisibleSteps(promotionArray)

  const maxQuantity = Math.max(...visibleSteps?.map((step) => step?.quantity))

  for (let i = 1; i < maxQuantity; i++) {
    const findedObject = visibleSteps.find((step) => step.quantity === i)

    if (!findedObject) {
      visibleSteps.push({ quantity: i, percent: 0, visible: false })
    }
  }

  return visibleSteps
}

function sortSteps(promotionArray: PromotionDiscounts[]) {
  return createNewPromotionsSteps(promotionArray)?.sort(
    (a, b) => a?.quantity - b?.quantity
  )
}

const statusPromotion = (appliedPromotion: number, dotIndex: number) => {
  return appliedPromotion < dotIndex
    ? 'not-check'
    : appliedPromotion > dotIndex
    ? 'checked'
    : 'same-index'
}

const BarStatus = ({ appliedPromotion, promoDiscounts }: BarStatusProps) => {
  const setClass = (index: number) => {
    return index === 0 ? 'visible' : index % 2 === 0 ? 'even' : 'odd'
  }

  const halfPercent = 100 / (sortSteps(promoDiscounts)?.length - 1)

  const loaderStatus = (index: number) => {
    return halfPercent * index
  }

  return (
    <div className="bar-status__loader">
      <div
        className="bar-status"
        style={{
          background: `linear-gradient(90deg, #f08769 ${loaderStatus(
            appliedPromotion
          )}%, #c4c4c4 ${loaderStatus(appliedPromotion)}%)`,
        }}
      >
        {sortSteps(promoDiscounts)?.map((e) => (
          <div key={e.percent} className={setClass(e?.quantity)}>
            <div
              className={`discount-checkpoint ${statusPromotion(
                appliedPromotion,
                e?.quantity
              )}`}
            >
              <span
                className={`discount-dot ${statusPromotion(
                  appliedPromotion,
                  e?.quantity
                )}`}
              />
              <span className="discount-quantity">{e?.quantity}</span>
            </div>
          </div>
        ))}
      </div>
      <p className="max-percent">
        {sortSteps(promoDiscounts)[promoDiscounts?.length - 1]?.percent}% OFF
      </p>
    </div>
  )
}

export default BarStatus
