import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import './styles.scss'
import type { CartItem } from 'src/sdk/cart'
import { useCart } from 'src/sdk/cart'
import { useSession } from 'src/sdk/session'
import { useCmsGlobalComponents } from 'src/contexts/CMSGlobalComponents/cms-global-components-context'

import BarContainer from './BarContainer'
import type { ProductType } from '../ProductDetails/types/ProductType'

interface ProgressiveDiscountBarProps {
  componentType?: string
  product?: ProductType
}

export interface CmsPromotionData {
  promotionId: string
  promotionName: string
  promotionPage: string
}

export interface CmsData {
  name: string
  id: string
  data: CmsPromotionData & {
    messagesSteps: [
      {
        step: string
        stepMessage: string
      }
    ]
  }
}

export interface PromotionData {
  name: string
  beginDateUtc: string
  endDateUtc: string
  isActive: boolean
  activeDaysOfWeek: []
  collectionsIsInclusive: boolean
  collections1BuyTogether:
    | [
        {
          id: string
          name: string
        }
      ]
    | []
  percentualDiscountValueList: [
    {
      quantity: number
      percent: number
    }
  ]
  recurrencyCrons: string[]
  listSku1BuyTogether:
    | [
        {
          id: string
          name: string
        }
      ]
    | []
}

interface CartItemsProps {
  quantity: number
  sku: string
  price?: number
  listPrice?: number
  productGroupID?: string
  brandName?: string
  BrandId?: string
  categories?: string[]
  collections: string[]
}

interface PromotionProductExtraData {
  sku: string
  brand: {
    name: string
  }
  brandId: number
  categoriesIds: string[] | null
  productId: string
  productClusters: Array<{
    id: string
    name: string
  }>
}

interface PromotionProductDataResult {
  product: PromotionProductExtraData
}

function isPromotionActive(promotion: PromotionData) {
  const todayDate = new Date()
  const beginDate = new Date(promotion?.beginDateUtc)
  const endDate = new Date(promotion?.endDateUtc)

  return promotion?.isActive && todayDate > beginDate && todayDate < endDate
}

function validateSku(sku: string, promotion: PromotionData) {
  const hasSku = promotion?.listSku1BuyTogether?.filter((e) => e.id === sku)

  if (promotion?.listSku1BuyTogether?.length === 0) {
    return true
  }

  return hasSku?.length > 0
}

function validadeCollection(product: ProductType, promotion: PromotionData) {
  if (promotion?.collections1BuyTogether?.length === 0) {
    return true
  }

  if (product?.productClusters !== null) {
    return (
      product?.productClusters?.filter((productCollection) =>
        promotion?.collections1BuyTogether?.some(
          (promotionCollection) =>
            promotionCollection?.id === productCollection?.id
        )
      ).length > 0
    )
  }

  return false
}

function validateBar(promotionData: PromotionData, product: ProductType) {
  return (
    isPromotionActive(promotionData) &&
    validateSku(product?.sku, promotionData) &&
    validadeCollection(product, promotionData)
  )
}

function validateItemCartCollection(
  item: CartItemsProps,
  promotion: PromotionData
) {
  const collectionsPromotion: Array<string | null> = []

  if (promotion?.collections1BuyTogether?.length === 0) {
    return true
  }

  promotion?.collections1BuyTogether?.forEach((cluster) => {
    collectionsPromotion?.push(cluster?.id?.toString())
  })

  return item?.collections?.some((e) => collectionsPromotion?.includes(e))
}

async function getExtraProductData(sku: string, channel: string) {
  try {
    const response = await axios.post('/api/getProductData', {
      productId: sku,
      channel,
    })

    return response.data
  } catch (error) {
    console.error(error)
    throw error
  }
}

async function getCartData(
  itemData: CartItem[],
  channel: string,
  setCartProducts: React.Dispatch<React.SetStateAction<CartItemsProps[]>>
) {
  const itemsCart: CartItemsProps[] = []

  await Promise.all(
    itemData?.map(async (item: CartItem) => {
      const { product: productExtraData } = (await getExtraProductData(
        item?.itemOffered?.sku,
        channel
      )) as PromotionProductDataResult

      const collections = productExtraData?.productClusters?.map(
        (cluster) => cluster.id ?? ''
      )

      const cartItem = {
        sku: item?.itemOffered?.sku,
        collections,
        quantity: item?.quantity,
        price: item?.price,
        listPrice: item?.listPrice,
      }

      itemsCart.push(cartItem)
    })
  )

  setCartProducts(itemsCart)
}

function verifyAppliedPromotion(
  cartProducts: CartItemsProps[],
  promotionData: PromotionData,
  setAppliedPromotion: React.Dispatch<React.SetStateAction<number>>
) {
  if (!(cartProducts?.length > 0 && promotionData)) {
    setAppliedPromotion(0)
  }

  let number = 0

  cartProducts?.forEach((item) => {
    if (
      validateSku(item?.sku, promotionData) &&
      validateItemCartCollection(item, promotionData)
    ) {
      number += item?.quantity
    }
  })

  setAppliedPromotion(number)
}

const ProgressiveDiscountBar = ({
  product,
  componentType,
}: ProgressiveDiscountBarProps) => {
  const { items, totalItems } = useCart()
  const { cmsGlobalComponents } = useCmsGlobalComponents()
  const { channel } = useSession()
  const [cmsPromotionData, setCmsPromotionData] = useState([] as CmsData[])
  const [promotionData, setPromotionData] = useState({} as PromotionData)
  const [viewBar, setViewBar] = useState(false)
  const [cartProducts, setCartProducts] = useState([] as CartItemsProps[])
  const [appliedPromotion, setAppliedPromotion] = useState(0)
  const hasCmsPromotionData = cmsPromotionData && cmsPromotionData?.length !== 0

  useEffect(() => {
    const progressiveDiscountBarList = cmsGlobalComponents?.sections?.filter(
      (section) => section?.name === 'ProgressiveDiscountBar'
    ) as unknown as CmsData[]

    if (
      progressiveDiscountBarList &&
      progressiveDiscountBarList?.length !== 0
    ) {
      setCmsPromotionData(progressiveDiscountBarList)
      axios
        .post('/api/getPromotionById', {
          promotionId: progressiveDiscountBarList?.[0]?.data?.promotionId,
        })
        .then((res) => {
          setPromotionData(res.data)
        })
    }
  }, [cmsGlobalComponents])

  const prevItemsRef = useRef([] as CartItem[])

  useEffect(() => {
    if (!(items && channel) || !hasCmsPromotionData) {
      return
    }

    if (JSON.stringify(prevItemsRef.current) !== JSON.stringify(items)) {
      getCartData(items, channel, setCartProducts)
    }

    prevItemsRef.current = items
  }, [totalItems, cmsPromotionData])

  useEffect(() => {
    if (product && promotionData) {
      setViewBar(validateBar(promotionData, product))
    }
  }, [product, promotionData])

  useEffect(() => {
    verifyAppliedPromotion(cartProducts, promotionData, setAppliedPromotion)
  }, [cartProducts, promotionData])

  return hasCmsPromotionData ? (
    <BarContainer
      promotionData={promotionData}
      componentType={componentType}
      viewBar={viewBar}
      appliedPromotion={appliedPromotion}
      cmsPromotionData={cmsPromotionData}
    />
  ) : null
}

export default ProgressiveDiscountBar
