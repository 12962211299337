import axios from 'axios'

const getAllCustomAppFromOrderForm = async (cartId: string | undefined) => {
  const { data } = await axios.post('/api/getOrderform', {
    orderformID: cartId,
  })

  return data?.customData?.customApps
}

export default getAllCustomAppFromOrderForm
