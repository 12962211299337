import type { FC } from 'react'
import React, { useContext, createContext, useState } from 'react'
import type { Products } from 'src/components/product/ProductKitLook/types'

const KitLookContext = createContext<KitLookContextProps>(
  {} as KitLookContextProps
)

export const KitLookProvider: FC<React.PropsWithChildren> = ({ children }) => {
  const [totalPrice, setTotalPrice] = useState(0)
  const [products, setProducts] = useState<Products>([])

  return (
    <KitLookContext.Provider
      value={{
        totalPrice,
        setTotalPrice,
        products,
        setProducts,
      }}
    >
      {children}
    </KitLookContext.Provider>
  )
}

export const useKitLookContext = () => {
  return useContext(KitLookContext)
}
