import type { FC } from 'react'
import React from 'react'
import './styles.scss'
import type { CartItem as ICartItem } from 'src/sdk/cart'
import type { ProductSummary_ProductFragment } from '@generated/graphql'

interface Props {
  item: ICartItem & {
    itemOffered: {
      isVariantOf: {
        hasVariant?: ProductSummary_ProductFragment['isVariantOf']['hasVariant']
      }
    }
  }
}

const ShowSizeSKU: FC<Props> = ({ item: { id, itemOffered } }) => {
  const idProduct = Number(id?.split(':', 3)[0])
  const skusList = itemOffered?.isVariantOf?.hasVariant

  const skuData = skusList?.find(
    ({ sku, additionalProperty }) =>
      Number(sku) === idProduct && additionalProperty
  )

  const hasVariant =
    itemOffered?.additionalProperty?.[0]?.valueReference === 'SPECIFICATION' ||
    skuData?.additionalProperty?.[0]?.valueReference === 'SPECIFICATION'

  const sizeSku =
    skuData?.additionalProperty?.[0]?.value ??
    itemOffered?.additionalProperty?.[0]?.value

  const additionalPropertyName =
    skuData?.additionalProperty?.[0]?.name ??
    itemOffered?.additionalProperty?.[0]?.name

  return (
    <>
      {hasVariant && sizeSku && (
        <div className="c-sku">
          <span className="c-sku__text">
            {skuData?.additionalProperty?.[0]?.name ??
              itemOffered?.additionalProperty?.[0]?.name}
            :{' '}
          </span>
          <span className="c-sku__value">
            {sizeSku}
            {additionalPropertyName === 'Espessura aprox' && 'mm'}
          </span>
        </div>
      )}
    </>
  )
}

export default ShowSizeSKU
