import axios from 'axios'
import { get } from 'idb-keyval'

import { syncCart } from './syncCart'

type CartGenerateProps = {
  reload?: boolean
  forceNewCart?: boolean
}

export const cartGenerate = async ({
  reload,
  forceNewCart,
}: CartGenerateProps) => {
  const cartDB = await get('fs::cart')

  if ((cartDB?.id === '' || forceNewCart) && navigator?.cookieEnabled) {
    const { data: newOrderFormId } = await axios.get(
      `/api/getOrderFormId${forceNewCart ? '?forceNewCart=true' : ''}`
    )

    syncCart({ newOrderFormId, reload })

    return newOrderFormId
  }

  return cartDB?.id
}
