import { set } from 'idb-keyval'
import type { CartItem } from 'src/sdk/cart'

type CartSyncProps = {
  newOrderFormId: string
  reload?: boolean
  items?: CartItem[]
}

export const syncCart = async ({
  newOrderFormId,
  reload,
  items,
}: CartSyncProps) => {
  if (newOrderFormId === '') {
    return
  }

  await set('fs::cart', {
    id: newOrderFormId,
    items: items ?? [],
    messages: [],
  }).then(() => {
    if (reload) {
      window.location.reload()
    }
  })
}
