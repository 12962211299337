import React, { memo } from 'react'
import { Script } from 'gatsby'

function GoogleTagManagerEco() {
  const scriptGoogleTagManagerEco =
    () => `>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var
    f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNod
    e.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-W6XP4NCM');`

  return (
    <>
      <Script
        key="gtm"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: scriptGoogleTagManagerEco(),
        }}
      />
    </>
  )
}

export default memo(GoogleTagManagerEco)
