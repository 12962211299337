module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"defer":true},
    },{
      plugin: require('../node_modules/@vtex/gatsby-plugin-thumbor/gatsby-browser.js'),
      options: {"plugins":[],"server":"https://lojavivara.vtexassets.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Vivara | Tornando cada história única e especial.","short_name":"Vivara","start_url":"/","display":"standalone","icon":"src/images/favicon.png","icons":[{"src":"/icons/vivara-icons/icon-48x48.svg","sizes":"48x48","type":"image/svg","purpose":"any"},{"src":"/icons/vivara-icons/icon-72x72.png","sizes":"57x57","type":"image/png","purpose":"any"},{"src":"/icons/vivara-icons/icon-72x72.png","sizes":"60x60","type":"image/png","purpose":"any"},{"src":"/icons/vivara-icons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"any"},{"src":"/icons/vivara-icons/icon-96x96.svg","sizes":"76x76","type":"image/svg","purpose":"any"},{"src":"/icons/vivara-icons/icon-96x96.svg","sizes":"96x96","type":"image/svg","purpose":"any"},{"src":"/icons/vivara-icons/icon-144x144.png","sizes":"114x114","type":"image/png","purpose":"any"},{"src":"/icons/vivara-icons/icon-144x144.png","sizes":"120x120","type":"image/png","purpose":"any"},{"src":"/icons/vivara-icons/icon-144x144.svg","sizes":"144x144","type":"image/svg","purpose":"any"},{"src":"/icons/vivara-icons/icon-168x168.png","sizes":"152x152","type":"image/png","purpose":"any"},{"src":"/icons/vivara-icons/icon-168x168.svg","sizes":"168x168","type":"image/svg","purpose":"any"},{"src":"/icons/vivara-icons/icon-192x192.png","sizes":"180x180","type":"image/png","purpose":"any"},{"src":"/icons/vivara-icons/icon-192x192.svg","sizes":"192x192","type":"image/svg","purpose":"any"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"026fd600cfec0499b2245b39467d5bbc"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
