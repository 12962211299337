import type { ReactNode } from 'react'
import axios from 'axios'
import React, {
  useMemo,
  useState,
  createContext,
  useContext,
  useEffect,
} from 'react'
import type { PromotionProps } from 'src/components/sections/PromotionBar/PromotionBar'

import { useCmsGlobalComponents } from './CMSGlobalComponents/cms-global-components-context'

export interface IContext {
  promotionData: PromotionProps[]
  setPromotionData: React.Dispatch<PromotionProps[]>
}

interface GetPromotionProps {
  url: string
  body: {
    promotionIds:
      | Array<{
          promotionId: string
          promotionName: string
          promotionPage: string
        }>
      | undefined
  }
  setPromotionData: React.Dispatch<PromotionProps[]>
  setPromotionsFetched: React.Dispatch<React.SetStateAction<boolean>>
}

const getPromotions = ({
  url,
  body: { promotionIds },
  setPromotionData,
  setPromotionsFetched,
}: GetPromotionProps) => {
  const temporaryArray: PromotionProps[] = []

  promotionIds?.forEach(async (promotionId, index) => {
    await axios
      .post(url, promotionId, {
        headers: {
          Accept: 'application/vnd.vtex.ds.v10+json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        temporaryArray.push({ ...response.data, cmsOptions: promotionId })
      })
      .then(() => {
        if (index === promotionIds.length - 1) {
          setPromotionData(temporaryArray)
          setPromotionsFetched(true)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  })
}

const PromotionDataContext = createContext<IContext>({} as IContext)

export const PromotionDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [promotionData, setPromotionData] = useState([] as PromotionProps[])
  const [promotionsFetched, setPromotionsFetched] = useState(false)

  const { cmsGlobalComponents } = useCmsGlobalComponents()

  useEffect(() => {
    if (!promotionsFetched && cmsGlobalComponents?.sections) {
      const promotionIds = cmsGlobalComponents?.sections?.find(
        (section: { name: string }) => section.name === 'PromotionBar'
      )?.data?.promotions

      if (promotionIds) {
        getPromotions({
          url: '/api/getPromotionById',
          body: { promotionIds },
          setPromotionData,
          setPromotionsFetched,
        })
      }
    }
  }, [cmsGlobalComponents?.sections, promotionsFetched])

  const value = useMemo(
    () => ({
      promotionData,
      setPromotionData,
    }),
    [promotionData]
  )

  return (
    <PromotionDataContext.Provider value={value}>
      {children}
    </PromotionDataContext.Provider>
  )
}

export const usePromotionDataContext = () => {
  return useContext(PromotionDataContext)
}
