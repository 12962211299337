import type { BrowserProductQueryQuery } from '@generated/graphql'

interface Props {
  url: string
  alternateName: string
}

function extractNumber(url: string, regex: RegExp) {
  const match = url.match(regex)

  return match ? parseFloat(match?.toString()[1]) : null
}

export function OrderImagesByRegex(images: Props[]) {
  const regex = /_\d_set/

  const productImages = images

  return [...productImages]?.sort(
    (
      a: {
        url: string
        alternateName: string
      },
      b: {
        url: string
        alternateName: string
      }
    ) => {
      const numberA = extractNumber(a.url, regex)
      const numberB = extractNumber(b.url, regex)

      if (numberA === null && numberB === null) {
        return 0
      }

      if (numberA === null) {
        return 1
      }

      if (numberB === null) {
        return -1
      }

      return numberA - numberB
    }
  )
}

export function getMainImageFromProduct(
  product: BrowserProductQueryQuery['product']
) {
  return (
    product?.image?.find((image) => image.url.includes('_1_set'))?.url ??
    product?.image?.[0]?.url
  )
}
