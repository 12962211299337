import React, { useState } from 'react'

import type { MessageProps } from './PromotionBar'

interface Props {
  message: MessageProps[]
}

const ModalPromotion = ({ message }: Props) => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <>
      <button
        className="promotion__bar-more"
        onClick={() => setOpenModal(!openModal)}
      >
        SAIBA MAIS
      </button>
      {openModal && (
        <div className="promo__modal-wrapper">
          <div className="promo__modal-container">
            <div className="promo__modal-button">
              <button onClick={() => setOpenModal(!openModal)} />
            </div>
            <p>ENTENDA AS REGRAS</p>
            <div className="promo__modal-promos">
              {message.map((m) => (
                <a
                  href={m.promoPage}
                  className="promo__modal-name-promo"
                  key={m.key}
                  target="_blank"
                  rel="noreferrer"
                >
                  {m.promoName}
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ModalPromotion
