import axios from 'axios'

interface MarketingData {
  orderformID: string
  utmSource?: string
  utmiCampaign?: string
  utmCampaign?: string
  marketingTags?: string[]
  removeTag?: boolean
  marketingTagOrigin?: 'others' | 'seller-code'
  oldSellerMarketingTags?: string[]
}

async function setMarketingDataInOrderForm(marketingData: MarketingData) {
  try {
    await axios
      .post('/api/setMarketingData', { ...marketingData })
      .then((response) => {
        return response.data
      })
  } catch (error) {
    console.error(error)
  }
}

export default setMarketingDataInOrderForm
