import { useGetThumborImageData } from '@vtex/gatsby-plugin-thumbor'
import { GatsbyImage } from 'gatsby-plugin-image'
import type { Dispatch, SetStateAction } from 'react'
import React, { memo, useEffect, useMemo, useState } from 'react'
import type { ThumborImageOptions } from '@vtex/gatsby-plugin-thumbor'
import type { GatsbyImageProps } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'

import './image.scss'

interface Props extends Omit<GatsbyImageProps, 'image'>, ThumborImageOptions {
  setAltForEvent?: (value: string) => void
  setPayloadEvent?: Dispatch<SetStateAction<string[]>>
  setPayloadPromoView?: Dispatch<
    SetStateAction<{ altForEvent: string; position: number }>
  >
  altData?: string
  placeholder?: string
}

function Image({
  baseUrl,
  width,
  height,
  sourceWidth,
  sourceHeight,
  aspectRatio,
  layout,
  placeholderURL,
  backgroundColor,
  breakpoints,
  options,
  setAltForEvent,
  setPayloadPromoView,
  setPayloadEvent,
  altData,
  placeholder,
  ...imgProps
}: Props) {
  const getImage = useGetThumborImageData()
  const { ref, inView } = useInView()
  const [load, setLoad] = useState(false)

  const image = useMemo(
    () =>
      getImage({
        baseUrl,
        width,
        height,
        sourceWidth,
        sourceHeight,
        aspectRatio,
        layout,
        placeholderURL,
        backgroundColor,
        breakpoints,
        options,
      }),
    [
      aspectRatio,
      backgroundColor,
      baseUrl,
      breakpoints,
      getImage,
      height,
      layout,
      options,
      placeholderURL,
      sourceHeight,
      sourceWidth,
      width,
    ]
  )

  useEffect(() => {
    if (inView && imgProps?.alt && setPayloadEvent) {
      setPayloadEvent((prevState) => [...prevState, imgProps?.alt])
    }
  }, [inView, imgProps?.alt, setPayloadEvent])

  useEffect(() => {
    if (inView && load === false && altData) {
      setAltForEvent?.(altData)
      setLoad(true)
    }
  }, [inView, altData])

  return (
    <>
      <div ref={ref} />
      <GatsbyImage
        {...imgProps}
        image={image}
        loading="eager"
        style={{ willChange: 'unset' }}
      />
    </>
  )
}

export default memo(Image)
