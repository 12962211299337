import type { ReactNode } from 'react'
import axios from 'axios'
import React, {
  useMemo,
  useState,
  createContext,
  useContext,
  useEffect,
} from 'react'
import type { CartItem } from 'src/sdk/cart'
import { useCart } from 'src/sdk/cart'
import type {
  CartItemsProps,
  PromotionProductDataResult,
} from 'src/components/sections/PromotionBar/PromotionBar'
import { useSession } from 'src/sdk/session'
import { processCategoriesIds } from 'src/components/sections/PromotionBar/ViewPromotionOnPage'

export interface IContext {
  cartData: CartItemsProps[]
  setCartData: React.Dispatch<React.SetStateAction<CartItemsProps[]>>
  totalItems: number
  setTotalItems: React.Dispatch<React.SetStateAction<number>>
}

const CartDataContext = createContext<IContext>({} as IContext)

export const CartDataProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [cartData, setCartData] = useState([] as CartItemsProps[])
  const [totalItems, setTotalItems] = useState(-1)
  const { items, totalItems: totalItemsCart } = useCart()
  const { channel } = useSession()

  const getExtraProductData = async (sku: string) => {
    try {
      const response = await axios.post('/api/getProductData', {
        productId: sku,
        channel,
      })

      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const getCartData = async (itemData: CartItem[]) => {
    const itemsCart: CartItemsProps[] = []

    await Promise.all(
      itemData.map(async (item: CartItem) => {
        const { product: productExtraData } = (await getExtraProductData(
          item.itemOffered.sku
        )) as PromotionProductDataResult

        const collections = productExtraData?.productClusters?.map(
          (cluster) => cluster.id ?? ''
        )

        const cartItem = {
          quantity: item.quantity,
          sku: item.itemOffered.sku,
          price: item?.price,
          listPrice: item?.listPrice,
          productGroupID: item.itemOffered.isVariantOf.productGroupID,
          brandName: productExtraData?.brand?.name,
          BrandId: productExtraData?.brandId?.toString(),
          categories: processCategoriesIds(productExtraData?.categoriesIds),
          collections,
          id: item?.itemOffered?.sku,
          categoriesIds: productExtraData?.categoriesIds,
          specificationGroups: productExtraData?.specificationGroups,
          slug: productExtraData?.slug,
          breadcrumbList: productExtraData?.breadcrumbList,
          name: productExtraData?.name,
          image: productExtraData?.image,
          brand: productExtraData?.brand,
          isVariantOf: productExtraData?.isVariantOf,
          additionalProperty: productExtraData?.additionalProperty,
          productId: productExtraData?.productId,
          productClusters: productExtraData?.productClusters,
        }

        itemsCart.push(cartItem)
      })
    )

    setCartData(itemsCart)
  }

  useEffect(() => {
    if (!items) {
      return
    }

    if (totalItemsCart !== totalItems) {
      getCartData(items)
      setTotalItems(totalItemsCart)
    }
  }, [totalItemsCart])

  const value = useMemo(
    () => ({
      cartData,
      setCartData,
      totalItems,
      setTotalItems,
    }),
    [cartData, totalItems]
  )

  return (
    <CartDataContext.Provider value={value}>
      {children}
    </CartDataContext.Provider>
  )
}

export const useCartDataContext = () => {
  return useContext(CartDataContext)
}
