import React from 'react'

import type { CmsData } from './ProgressiveDiscountBar'

interface MessagePromotionProps {
  appliedPromotion: number
  promoDiscounts: [
    {
      quantity: number
      percent: number
    }
  ]
  componentType?: string
  cmsPromotionData: CmsData[]
}

interface PromotionDiscounts {
  quantity: number
  percent: number
}

interface PromotionMessage {
  step: string
  stepMessage: string
}

function completePromoDiscounts(promoDiscounts: PromotionDiscounts[]) {
  for (let i = 0; i < promoDiscounts.length; i++) {
    const findedObject = promoDiscounts.find((step) => step.quantity === i)

    if (!findedObject) {
      promoDiscounts.push({ quantity: i, percent: 0 })
    }
  }

  return promoDiscounts
}

function sortSteps(promotionArray: PromotionDiscounts[]) {
  const completedPromoDiscounts = completePromoDiscounts(promotionArray)

  return [...completedPromoDiscounts]?.sort((a, b) => a?.quantity - b?.quantity)
}

function addMessagesToDiscounts(
  promodiscounts: PromotionDiscounts[],
  cmsPromotionDataMessages: PromotionMessage[]
) {
  let lastValidMessage = ''
  const sortedSteps = sortSteps(promodiscounts)

  return sortedSteps?.map((discount) => {
    const message = cmsPromotionDataMessages?.find(
      (dataMessage) => parseFloat(dataMessage.step) === discount?.quantity
    )

    if (message) {
      lastValidMessage = message?.stepMessage
    }

    if (discount.quantity === 0 && !message?.stepMessage) {
      lastValidMessage = 'Aproveite o desconto progressivo!'
    }

    return {
      ...discount,
      stepMessage: message ? message?.stepMessage : lastValidMessage,
    }
  })
}

const MessagePromotion = ({
  appliedPromotion,
  promoDiscounts,
  componentType,
  cmsPromotionData,
}: MessagePromotionProps) => {
  const { messagesSteps } = cmsPromotionData[0].data
  const messages = addMessagesToDiscounts(promoDiscounts, messagesSteps)
  const lastMessage = messages[messages.length - 1]
  const actualMessage = messages?.filter(
    (message) => message?.quantity === appliedPromotion
  )[0]

  return (
    <div className={`message-bar ${componentType}`} id="progressive-message">
      <p className="message-text">
        {appliedPromotion > lastMessage?.quantity
          ? lastMessage?.stepMessage
          : actualMessage?.stepMessage}
      </p>
    </div>
  )
}

export default MessagePromotion
