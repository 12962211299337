import axios from 'axios'
import type { Dispatch, SetStateAction } from 'react'
import { useState, useEffect, useCallback } from 'react'
import { get } from 'idb-keyval'
import type { BrowserProductQueryQuery } from '@generated/graphql'

type AvailableItemParam = {
  sku: string
  product?: BrowserProductQueryQuery['product']
}

type GetAvailableItemsData = {
  url: string
  params: Record<string, unknown>
  setAvailableItemsValue: Dispatch<SetStateAction<number | false | undefined>>
}

interface AvailableItemsData {
  itemId: string
  sellers: Array<{
    commertialOffer: {
      AvailableQuantity: number
    }
  }>
}

const getAvailableItems = async ({
  url,
  params,
  setAvailableItemsValue,
}: GetAvailableItemsData) => {
  try {
    const { skuId, regionId } = params
    const { data } = await axios.get(url, {
      params: {
        skuId,
        regionId,
      },
    })

    if (data?.items && data?.items?.length > 0) {
      const filteredValue = data?.items?.filter(
        (item: AvailableItemsData) => item.itemId === skuId
      )

      setAvailableItemsValue(
        filteredValue?.[0]?.sellers?.[0]?.commertialOffer?.AvailableQuantity
      )
    } else {
      setAvailableItemsValue(false)
    }
  } catch (error) {
    console.error(error)
  }
}

export function useAvailableItems({ sku, product }: AvailableItemParam) {
  const [availableItemsValue, setAvailableItemsValue] = useState<
    number | false
  >()

  const getAvailableItemsWithRegion = useCallback(async () => {
    const inStock = product?.isVariantOf?.hasVariant?.find(
      (variant) =>
        variant?.offers?.offers?.[0]?.availability ===
          'https://schema.org/InStock' && variant?.sku === sku
    )

    if (!product || product?.isVariantOf?.hasVariant?.length === 0 || inStock) {
      const session = await get('fs::session')
      const regionId = session?.channel
        ? JSON.parse(session?.channel)?.regionId
        : ''

      await getAvailableItems({
        url: '/api/getAvailableItemsIo',
        params: { skuId: sku, regionId },
        setAvailableItemsValue,
      })

      return true
    }

    return false
  }, [sku, product])

  useEffect(() => {
    if (sku) {
      getAvailableItemsWithRegion()
    }
  }, [sku, getAvailableItemsWithRegion])

  return { availableItemsValue }
}
