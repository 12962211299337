import axios from 'axios'
import { isStorageAvailable } from 'src/utils/isStorageAvailable'

import { useCart } from '.'
import * as storeConfig from '../../../store.config'

const { checkoutUrl } = storeConfig

export const useCheckoutButton = () => {
  const { isValidating, id } = useCart()

  const onClick = async (e: React.MouseEvent) => {
    e.preventDefault()

    if (isValidating && !id) {
      return
    }

    const getLocalStorage = isStorageAvailable()
      ? localStorage.getItem('telemarketing')
      : '{}'

    const cachedTelemarketingStatus = JSON.parse(getLocalStorage ?? '{}')

    if (
      cachedTelemarketingStatus &&
      cachedTelemarketingStatus !== 'undefined'
    ) {
      if (cachedTelemarketingStatus?.customerEmail) {
        await axios.post('/api/setEmailClientProfileData', {
          orderFormId: id,
          email: cachedTelemarketingStatus?.customerEmail,
        })
      }

      window.location.href = `${checkoutUrl}?orderFormId=${id}`
    } else {
      window.location.href = `${checkoutUrl}?orderFormId=${id}`
    }
  }

  return {
    onClick,
    disabled: isValidating,
    'data-testid': 'checkout-button',
  }
}
