import React from 'react'
import Logo from 'src/components/icons/Logo'

import './styles.scss'

function CookieEnablePrompt() {
  return (
    <div className="cookieEnablePrompt">
      <div className="cookieEnablePrompt-content">
        <div className="cookieEnablePrompt-containerLogo">
          <Logo />
        </div>
        <h1>Os cookies estão desativados</h1>
        <p className="cookieEnablePrompt-message">
          Os cookies do seu navegador estão desativados, por isso você pode
          estar tendo problemas para acessar nosso site. &nbsp;
          <strong>
            Por favor, ative-os e tente novamente. &nbsp;
            <a
              href="https://support.google.com/accounts/answer/61416?hl=pt-BR"
              target="_blank"
              rel="noreferrer"
            >
              SAIBA MAIS
            </a>
          </strong>
        </p>

        <div className="cookieEnablePrompt-containerButton">
          <button onClick={() => window.location.reload()}>
            Tentar novamente
          </button>
        </div>
      </div>
    </div>
  )
}

export default CookieEnablePrompt
