import type { AnalyticsEvent } from '@faststore/sdk'
import { useAnalyticsEvent } from '@faststore/sdk'

import sendEvent from './platform/vtex'

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer ?? []
}

type PageViewEvent = {
  name: 'page_view'
  params: {
    state: string
    city: string
    cep: string
    phone: string
    visitorId: string
    userId: string
    email: string
    pageType: string
    location: string
    page: string
    referrer: string
  }
}

type WishlistProduct = {
  name: 'add_wish_list'
  params: {
    productId: string
    name: string
    price: number
    brand: string
    category: string
    position: number
  }
}

type RemoveWishList = {
  name: 'remove_wish_list'
  params: {
    position: number
    category: string
    brand: string
    price: number
    name: string
    productId: string
  }
}

type PromoClick = {
  name: 'promo_click'
  params: Array<{
    id: string
    name: string
    creative: string
    position: string
  }>
}

export type PromoView = {
  name: 'promo_view'
  params: Array<{
    id: string
    name: string
    creative: string
    position: string | number | null
  }>
}
export type ErrorComponent = {
  name: 'erro_componente'
  params: {
    visitorId: string
    email: string
    component: string
    data: unknown
    location: string
    page: string
    referrer: string
  }
}

export type Promo = {
  id: string
  name: string
  creative: string
  position: string | number | null
}

type TAnalyticsData =
  | PageViewEvent
  | RemoveWishList
  | WishlistProduct
  | PromoClick
  | PromoView
  | ErrorComponent

const parseToUniversalAnalyticsData = (
  evt: AnalyticsEvent | TAnalyticsData
) => {
  const promotions = (params: Promo[]) => {
    return params?.map((item: Promo) => ({
      id: item.id,
      name: item.name,
      creative: item.creative,
      position: item.position,
    }))
  }

  switch (evt.name) {
    case 'view_item':
      return {
        event: 'productDetail',
        ecommerce: {
          detail: {
            products: (evt.params.items ?? []).map((item) => ({
              brand: item.item_brand,
              category: item.item_category,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              id: item.product_reference_id,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              variant: item.item_variant_name,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              name: item.item_name,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              dimension1: item.dimension1,
              price: item?.price,
            })),
          },
        },
      }

    case 'select_item':
      return {
        event: 'productClick',
        ecommerce: {
          click: {
            actionField: { list: evt.params.item_list_name },
            products: (evt.params.items ?? []).map((item) => ({
              brand: item.item_brand,
              category: item.item_category,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              id: item.product_reference_id,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              variant: item.item_variant_name,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              name: item.item_name,
              dimension1: '',
              dimension2: '',
              dimension3: '',
              dimension4: '',
              price: item?.price,
              position: item.index,
            })),
          },
        },
      }

    case 'add_to_cart':
      return {
        event: 'addToCart',
        ecommerce: {
          currencyCode: evt.params.currency,
          add: {
            products: (evt.params.items ?? []).map((item) => ({
              brand: item.item_brand,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              id: item.product_reference_id,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              variant: item.item_variant_name,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              name: item.item_name,
              price: item?.price,
              quantity: item.quantity,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              sku: item.dimension1,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              dimension1: item.item_id,
              dimension2: '',
              dimension3: '',
            })),
          },
        },
      }

    case 'remove_from_cart':
      return {
        event: 'removeFromCart',
        ecommerce: {
          currencyCode: evt.params.currency,
          remove: {
            products: (evt.params.items ?? []).map((items) => ({
              brand: items.item_brand,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              id: items.product_reference_id,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              variant: items.item_variant_name,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              name: items.item_name,
              price: items?.price,
              quantity: items.quantity,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              sku: items.dimension1,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              dimension1: items.item_id,
              dimension2: '',
              dimension3: '',
            })),
          },
        },
      }

    case 'view_item_list': {
      return {
        event: 'productImpression',
        ecommerce: {
          impressions: (evt.params.items ?? []).map((item) => ({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            name: item.item_name,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            id: item.product_reference_id,
            price: item?.price,
            brand: item.item_brand,
            category: item.item_category,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            variant: item.item_variant_name,
            list: item.item_list_id,
            position: item.index,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dimension1: item.dimension1,
          })),
        },
      }
    }

    case 'page_view':
      return {
        event: 'pageView',
        userData: {
          visitorId: evt.params.visitorId,
          email: evt.params.email,
          phone: evt.params.phone,
          city: evt.params.city,
          state: evt.params.state,
          cep: evt.params.cep,
          userId: evt.params.userId,
        },
        pageType: evt.params.pageType,
        location: evt.params.location,
        page: evt.params.page,
      }

    case 'erro_componente':
      return {
        event: 'ErroComponent',
        userData: {
          visitorId: evt.params.visitorId,
          data: evt.params.data,
          email: evt.params.email,
        },
        pageType: evt.params.component,
        location: evt.params.location,
        page: evt.params.page,
      }

    case 'add_wish_list':
      return {
        event: 'addToWishlist',
        products: {
          id: evt.params.productId,
          name: evt.params.name,
          price: evt.params?.price,
          brand: evt.params.brand,
          category: evt.params.category,
          position: evt.params.position,
        },
      }

    case 'remove_wish_list':
      return {
        event: 'removeWishList',
        products: {
          id: evt.params.productId,
          name: evt.params.name,
          price: evt.params?.price,
          brand: evt.params.brand,
          category: evt.params.category,
          position: evt.params.position,
        },
      }

    case 'promo_click':
      return {
        event: 'promoClick',
        ecommerce: {
          promoClick: {
            promotions: promotions(evt.params ?? []),
          },
        },
      }

    case 'promo_view':
      return {
        event: 'promoView',
        ecommerce: {
          promoView: {
            promotions: promotions(evt.params ?? evt.params[0]),
          },
        },
      }

    default: {
      return null
    }
  }
}

export const AnalyticsHandler = () => {
  useAnalyticsEvent((event: AnalyticsEvent) => {
    const universalAnalyticsData = parseToUniversalAnalyticsData(event)

    // Cleans the ecommerce object before pushing a new one
    // This prevents the new data from getting perged with the previous one
    // which could lead do inacurate and old data being sent with events
    //
    // source: https://developers.google.com/tag-manager/ecommerce-ga4?hl=pt-br#clearing_the_ecommerce_object

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(universalAnalyticsData)

    sendEvent(event)
  })

  return null
}

export default AnalyticsHandler
