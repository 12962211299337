import axios from 'axios'

import changeOpenTextField from './changeOpenTextField'
import setMarketingDataInOrderForm from './setMarketingDataInOrderForm'

async function getDataSellerCode(orderFormId: string, code: string) {
  try {
    const { data: orderFormData } = await axios.post('/api/getOrderform', {
      orderformID: orderFormId,
    })

    const codeOld = orderFormData?.openTextField?.value

    await axios
      .post('/api/getDataSellerCode', { code, codeOld })
      .then((response) => {
        if (
          !response?.data ||
          response?.data?.discountSellerCode?.ativo !== true ||
          !response?.data?.discountSellerCode?.vendedorsemdesc
        ) {
          return
        }

        changeOpenTextField(orderFormId, {
          value: response?.data?.discountSellerCode?.vendedorsemdesc,
        })

        const { marketingTagSuffix } = response.data.discountSellerCode

        const { marketingTag: currentMarketingTag } =
          response?.data?.discountSellerCodeCurrent

        setMarketingDataInOrderForm({
          orderformID: orderFormId,
          marketingTags: [
            marketingTagSuffix?.length > 0 ? marketingTagSuffix : '',
          ],
          marketingTagOrigin: 'seller-code',
          oldSellerMarketingTags: [currentMarketingTag],
        })
      })
  } catch (error) {
    console.error(error)
  }
}

export default getDataSellerCode
