import type { ReactNode } from 'react'
import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'
import type { CmsSection } from 'src/components/common/Header/Header'

type CmsGlobalComponentsProviderProps = {
  children: ReactNode
  currentCMSGlobalComponents?: Record<string, CmsSection[]> | null
}

type CmsGlobalComponentsContextType = {
  cmsGlobalComponents?: Record<string, CmsSection[]> | null
}

const CmsGlobalComponentsContext =
  createContext<CmsGlobalComponentsContextType>({
    cmsGlobalComponents: null,
  })

export const CmsGlobalComponentsProvider = ({
  children,
  currentCMSGlobalComponents,
}: CmsGlobalComponentsProviderProps) => {
  const [cmsGlobalComponents, setCmsGlobalComponents] = useState<
    Record<string, CmsSection[]> | null | undefined
  >(undefined)

  const SearchParams = new URLSearchParams(window?.location?.search)
  const contentType = SearchParams.get('contentType')
  const documentId = SearchParams.get('documentId')
  const versionId = SearchParams.get('versionId')

  useEffect(() => {
    const getCmsData = async () => {
      try {
        const response = await axios.post(
          '/api/getCmsContent',
          {
            contentType,
            documentId,
            versionId,
          },
          {
            headers: {
              Accept: 'application/vnd.vtex.ds.v10+json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
          }
        )

        setCmsGlobalComponents(response?.data)
      } catch (error) {
        console.error('Error', error)
      }
    }

    const fetchData = async () => {
      if (
        contentType &&
        contentType === 'globalComponents' &&
        documentId &&
        versionId
      ) {
        await getCmsData()
      } else {
        setCmsGlobalComponents(currentCMSGlobalComponents)
      }
    }

    fetchData()
  }, [contentType, currentCMSGlobalComponents, documentId, versionId])

  return (
    <CmsGlobalComponentsContext.Provider value={{ cmsGlobalComponents }}>
      {children}
    </CmsGlobalComponentsContext.Provider>
  )
}

export const useCmsGlobalComponents = () => {
  const { cmsGlobalComponents } = useContext(CmsGlobalComponentsContext)

  return { cmsGlobalComponents }
}
