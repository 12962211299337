import { Script } from 'gatsby'
import React from 'react'

const Clarity = () => {
  const scriptClarity = () => `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "j6etmug1c0");
    `

  return (
    <Script
      key="clarity"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: scriptClarity(),
      }}
      async
    />
  )
}

export default Clarity
