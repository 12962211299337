let waitRequestUpdate = false

export const changeWaitRequestUpdate = (value: boolean) => {
  waitRequestUpdate = value
}

export const waitRequest = async () => {
  await new Promise((resolve) => {
    if (!waitRequestUpdate) {
      resolve(true)

      return true
    }

    const interval = setInterval(() => {
      if (!waitRequestUpdate) {
        clearInterval(interval)
        resolve(true)
      }
    }, 200)

    return false
  })
}
