import type { PromotionProps, CartItemsProps } from './PromotionBar'

export const isGlobalPromotion = (promotion: PromotionProps) => {
  return (
    promotion.categories.length === 0 &&
    promotion.brands.length === 0 &&
    promotion.skus.length === 0 &&
    promotion.collections.length === 0 &&
    promotion.products.length === 0
  )
}

const validateCategoryPromotion = (
  promotion: PromotionProps,
  itemData: CartItemsProps
) => {
  const hasFindCategory = itemData.categories.some((value: string) =>
    promotion.categories.some(
      (obj: { id: string; name: string }) => obj.id === value
    )
  )

  if (promotion.categories.length === 0) {
    return true
  }

  return hasFindCategory
    ? promotion.categoriesAreInclusive
    : promotion.categoriesAreInclusive === false
}

const validateBrandPromotion = (
  promotion: PromotionProps,
  itemData: CartItemsProps
) => {
  const hasFindBrand = promotion.brands.some(
    (brand: { id: string; name: string }) => brand.id === itemData.BrandId
  )

  if (promotion.brands.length === 0) {
    return true
  }

  return hasFindBrand
    ? promotion.brandsAreInclusive
    : promotion.brandsAreInclusive === false
}

const validateCollectionPromotion = (
  promotion: PromotionProps,
  itemData: CartItemsProps
) => {
  const hasFindCollection = itemData.collections.some((value: string) =>
    promotion.collections.some(
      (obj: { id: string; name: string }) => obj.id === value
    )
  )

  if (promotion.collections.length === 0) {
    return true
  }

  return hasFindCollection
    ? promotion.collectionsIsInclusive
    : promotion.collectionsIsInclusive === false
}

const validateProductPromotion = (
  promotion: PromotionProps,
  itemData: CartItemsProps
) => {
  const hasFindProduct = promotion.products.some(
    (p: { id: string; name: string }) => p.id === itemData.productGroupID
  )

  if (promotion.products.length === 0) {
    return true
  }

  return hasFindProduct
    ? promotion.productsAreInclusive
    : promotion.productsAreInclusive === false
}

const validateSkuPromotion = (
  promotion: PromotionProps,
  itemData: CartItemsProps
) => {
  const hasFindSku = promotion.skus.some(
    (p: { id: string; name: string }) => p.id === itemData.sku
  )

  if (promotion.skus.length === 0) {
    return true
  }

  return hasFindSku
    ? promotion.skusAreInclusive
    : promotion.skusAreInclusive === false
}

export const validatePromotionOnCart = (
  itensData: CartItemsProps[],
  promotion: PromotionProps,
  activePromotionsData: Array<PromotionProps & { totalPrice: number }>
) => {
  let price = 0

  if (isGlobalPromotion(promotion)) {
    itensData.forEach((item: CartItemsProps) => {
      price += item?.price * item.quantity
    })
  } else {
    itensData.forEach((item: CartItemsProps) => {
      if (
        validateCategoryPromotion(promotion, item) &&
        validateBrandPromotion(promotion, item) &&
        validateCollectionPromotion(promotion, item) &&
        validateProductPromotion(promotion, item) &&
        validateSkuPromotion(promotion, item)
      ) {
        price += item?.price * item.quantity
      }
    })
  }

  if (
    promotion.utmCampaign === undefined &&
    promotion.totalValueFloor !== 0 &&
    promotion.isActive === true &&
    promotion.paymentsMethods.length === 0 &&
    promotion.marketingTags.length === 0 &&
    new Date() <= new Date(promotion.endDateUtc) &&
    promotion.zipCodeRanges.length === 0 &&
    promotion.totalValuePurchase === 0 &&
    promotion.minInstallment === 0 &&
    promotion.maxInstallment === 0
  ) {
    activePromotionsData.push({ ...promotion, totalPrice: price })
  }
}
