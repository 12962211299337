import axios from 'axios'

import getAllCustomAppFromOrderForm from './getAllCustomAppsFromOrderForm'
import type { ICustomApps } from './handleOrderKpiData'
import {
  changeWaitUpdateCustomData,
  setOrderKpiMarketingTag,
  waitCustomData,
} from './handleOrderKpiData'

interface ICustomAppDataRemove {
  appName: string
  products: string[]
}

const newListProductsAfterRemoval = async (
  cartId: string | undefined,
  productId: string
) => {
  const customAppDataRemove: ICustomAppDataRemove = {
    appName: '',
    products: [],
  }

  const customApps = await getAllCustomAppFromOrderForm(cartId)

  customApps?.some((app: ICustomApps) => {
    customAppDataRemove.products = app?.fields?.products
      ?.split(',')
      ?.filter((itemId) => {
        if (itemId !== productId) {
          return true
        }

        customAppDataRemove.appName = app?.id

        return false
      })

    return customAppDataRemove.appName
  })

  return customAppDataRemove
}

const removeProductKpiFromOrderForm = async (
  cartId: string | undefined,
  productId: string
) => {
  try {
    await waitCustomData()
    changeWaitUpdateCustomData(true)

    const { appName, products } = await newListProductsAfterRemoval(
      cartId,
      productId
    )

    if (appName) {
      const body = {
        orderformID: cartId,
        appName,
        fieldName: 'products',
        fieldValue: `${products}`,
      }

      if (products?.length) {
        await axios.post('/api/setCustomData', body)
      } else {
        const nameMarketingTag = appName?.replace('-data', '')

        await Promise.all([
          axios.delete('/api/removeCustomDataField', {
            params: body,
          }),
          setOrderKpiMarketingTag(nameMarketingTag, cartId, true),
        ])
      }

      return true
    }

    return false
  } catch (error) {
    throw new Error(`Um erro ocorreu: ${error.message}`)
  } finally {
    changeWaitUpdateCustomData(false)
  }
}

export default removeProductKpiFromOrderForm
