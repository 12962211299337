import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Modal } from '@faststore/ui'

import type { CmsPromotionData } from './ProgressiveDiscountBar'

const ModalProgressiveBar = ({
  promotionName,
  promotionPage,
  promotionId,
}: CmsPromotionData) => {
  const [viewModal, setViewModal] = useState(false)

  return (
    <div id="modal-progressive-container" className="modal-progressive__bar">
      <button className="view-modal" onClick={() => setViewModal(true)}>
        SAIBA MAIS
      </button>
      <Modal isOpen={viewModal} className="modal-wrapper">
        <div>
          <button className="close-modal" onClick={() => setViewModal(false)} />
          <h2 className="modal-title">ENTENDA AS REGRAS</h2>
          <Link id={promotionId} className="promotion-link" to={promotionPage}>
            {promotionName}
          </Link>
        </div>
      </Modal>
    </div>
  )
}

export default ModalProgressiveBar
