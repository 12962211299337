import React, { memo } from 'react'
import { Script } from 'gatsby'

function APMAzure() {
  return (
    <>
      <Script
        key="apm-azure.js-script"
        type="text/javascript"
        async
        src="/apm-azure.js"
      />
    </>
  )
}

export default memo(APMAzure)
