// eslint-disable-next-line max-params
export function sendErrorLog({
  error,
  URLError,
  errorInfo,
  files,
}: ErrorLogSending) {
  const date = new Date()
  const dateNow = date?.toISOString()
  const errorData =
    typeof errorInfo === 'object' && errorInfo !== null
      ? errorInfo
      : { errorInfo }

  const environment = window?.location?.hostname.includes('localhost')
    ? 'localhost'
    : 'production'

  window?.appInsights?.trackException(error, {
    ...errorData,
    date: dateNow,
    url: URLError,
    environment,
    files,
    tag: 'Quality error',
  })
}

type ErrorLogSending = {
  error: unknown
  URLError: string
  errorInfo: unknown
  files?: string
}
