type GenericObject = { [key: string]: any }

export const deepObjectCopy = (obj: GenericObject) => {
  return JSON.parse(JSON.stringify(obj))
}

export const getSpecificationValue = (
  specificationName: string | null,
  productSpecifications?: {
    specifications: Array<{
      name: string | null
      values: Array<string | null> | null
    } | null> | null
  }
) => {
  const specification = productSpecifications?.specifications?.find(
    (spec) => spec?.name === specificationName
  )

  return specification?.values?.[0] ?? ''
}

export const getProductTaxonomy = (
  breadcrumbList:
    | {
        itemListElement: Array<{ name: string }>
      }
    | undefined
) => {
  const breadcrumbName = breadcrumbList?.itemListElement?.map(
    (breadcrumb) => breadcrumb?.name
  )

  const taxonomy = breadcrumbName

  taxonomy?.shift()
  taxonomy?.pop()

  return taxonomy ?? []
}
