import React from 'react'

import MessagePromotion from './MessagePromotion'
import BarStatus from './BarStatus'
import ModalProgressiveBar from './ModalProgressiveBar'
import type { CmsData, PromotionData } from './ProgressiveDiscountBar'

interface BarContainerProps {
  promotionData: PromotionData
  componentType: string | undefined
  viewBar: boolean
  appliedPromotion: number
  cmsPromotionData: CmsData[]
}

const BarContainer = ({
  promotionData,
  componentType,
  viewBar,
  appliedPromotion,
  cmsPromotionData,
}: BarContainerProps) => {
  const promoDiscounts = promotionData?.percentualDiscountValueList

  if (promoDiscounts && promoDiscounts[0]?.quantity !== 0) {
    promoDiscounts?.unshift({
      quantity: 0,
      percent: 0,
    })
  }

  return componentType === 'pdp' ? (
    <>
      {viewBar && (
        <>
          <div id="bar-container" className="bar-container">
            <MessagePromotion
              componentType={componentType}
              appliedPromotion={appliedPromotion}
              promoDiscounts={promoDiscounts}
              cmsPromotionData={cmsPromotionData}
            />
            <BarStatus
              appliedPromotion={appliedPromotion}
              promoDiscounts={promoDiscounts}
            />
          </div>
          <ModalProgressiveBar
            promotionName={cmsPromotionData[0]?.data?.promotionName}
            promotionId={cmsPromotionData[0]?.data?.promotionId}
            promotionPage={cmsPromotionData[0]?.data?.promotionPage}
          />
        </>
      )}
    </>
  ) : (
    <>
      {appliedPromotion !== 0 && (
        <div id="bar-container" className="bar-container cart">
          <MessagePromotion
            componentType={componentType}
            appliedPromotion={appliedPromotion}
            promoDiscounts={promoDiscounts}
            cmsPromotionData={cmsPromotionData}
          />
        </div>
      )}
    </>
  )
}

export default BarContainer
