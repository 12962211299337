import type { InsiderWishlistProduct } from 'src/components/wishlist/WishListInsiderUpdater'
import axios from 'axios'
import type { BrowserProductQueryQuery } from '@generated/graphql'

import addProductToInsiderWishlistObject from './addProductToInsiderWishlistObject'

const updateInsiderWishlistObject = async (
  productId: string,
  method: string
) => {
  if (!window.insider_object?.wishlist) {
    return
  }

  let data: BrowserProductQueryQuery
  let itemToRemove: InsiderWishlistProduct
  let updatedTotalPrice: number

  switch (method) {
    case 'remove':
      itemToRemove = window.insider_object.wishlist.line_items.find(
        (item: InsiderWishlistProduct) =>
          item.product.id === productId ||
          item.product.custom.sku_id === productId
      )

      if (!itemToRemove) {
        return
      }

      window.insider_object.wishlist.line_items =
        window.insider_object.wishlist.line_items.filter(
          (item: InsiderWishlistProduct) => item !== itemToRemove
        )

      updatedTotalPrice =
        Number(window.insider_object.wishlist.total) -
        Number(itemToRemove.subtotal)

      window.insider_object.wishlist.total = updatedTotalPrice.toFixed(2)

      break

    case 'add':
      try {
        const response = await axios.post('/api/getProductData', {
          productId,
          channel: '',
        })

        data = response.data
      } catch (error) {
        console.error(error)
        throw error
      }

      addProductToInsiderWishlistObject(data)

      break

    default:
      break
  }
}

export default updateInsiderWishlistObject
