import React, { useEffect, useState } from 'react'
import { usePromotionDataContext } from 'src/contexts/promotion-data-context'
import { useCartDataContext } from 'src/contexts/card-data-context'
import type { StoreProductGroup } from '@generated/graphql'

import { validatePromotionOnCart } from './ValidatePromotionOnCart'
import { viewPromotionOnPage } from './ViewPromotionOnPage'
import PdpPromotionBar from './PdpPromotionBar'
import CartPromotionBar from './CartPromotionBar'
import {
  orderPromotionData,
  filterPromotionByCartItemPrice,
  filterPromotionByVisiblePromo,
  calculateMissingPrice,
  getMaxPromoPrice,
} from './utils'

import './styles.scss'

export interface PromotionProduct {
  sku: string
  productClusters: Array<{ id: string | null; name: string | null }> | null
  brandId: number
  brand: {
    name: string
  }
  categoriesIds: string[] | null
  productId: string
}

export interface PromotionProductExtraData {
  sku: string
  brand: {
    name: string
  }
  brandId: number
  categoriesIds: string[] | null
  productId: string
  productClusters: Array<{
    id: string
    name: string
  }>
  specificationGroups: Array<{
    name: string
    specifications: Array<{
      name: string
      values: string[]
    }>
  }>
  slug: string
  breadcrumbList: {
    itemListElement: Array<{
      name: string
    }>
  }
  name: string
  isVariantOf: StoreProductGroup
  additionalProperty: Array<{
    value: string
  }>
  image: Array<{
    url: string
  }>
}

export interface PromotionProductDataResult {
  product: PromotionProductExtraData
}

interface Props {
  product?: PromotionProduct
  componentType: string
}

export type MaxPriceProps = {
  maxPromoPrice: number
  missingPrice: number
}

export type CartItemsProps = {
  quantity: number
  sku: string
  id: string
  price: number
  listPrice: number
  productGroupID: string
  brandName: string
  BrandId: string
  categories: string[]
  collections: string[]
  specificationGroups: Array<{
    name: string
    specifications: Array<{
      name: string
      values: string[]
    }>
  }>
  breadcrumbList: {
    itemListElement: Array<{
      name: string
    }>
  }
  brand: {
    name: string
  }
  productId: string
  name: string
  image: Array<{
    url: string
  }>
  additionalProperty: Array<{
    value: string
  }>
  slug: string
}

export type PromotionProps = {
  slasIds: string[]
  minInstallment: number
  maxInstallment: number
  totalValuePurchase: number
  zipCodeRanges: Array<{
    zipCodeFrom: string
    zipCodeTo: string
    inclusive: boolean
  }>
  endDateUtc: string | number | Date
  marketingTags: string[]
  paymentsMethods: Array<{ id: string; name: string }>
  isActive: boolean
  utmCampaign?: string
  categories: Array<{ id: string; name: string }>
  collections: Array<{ id: string; name: string }>
  brands: Array<{ id: string; name: string }>
  skus: Array<{ id: string; name: string }>
  products: Array<{ id: string; name: string }>
  collectionsIsInclusive: boolean
  skusAreInclusive: boolean
  productsAreInclusive: boolean
  categoriesAreInclusive: boolean
  brandsAreInclusive: boolean
  cmsOptions: { promotionName: string; promotionPage: string }
  totalValueFloor: number
}

export type MessageProps = {
  floorPrice: number
  cartItemPrice: number
  promoName: string
  promoPage: string
  priceStatus: boolean
  key: number
  brands: Array<{ id: string; name: string }>
  skus: Array<{ id: string; name: string }>
  products: Array<{ id: string; name: string }>
  collections: Array<{ id: string; name: string }>
  categories: Array<{ id: string; name: string }>
  visiblePromo?: boolean
  totalValueFloor?: number
}

export const setMessageInfo = (messageInfoData: MessageProps[]) => {
  const completedPromotion = messageInfoData.filter(
    (m: MessageProps) => m?.priceStatus
  )

  const nextAvailablePromotion = messageInfoData.filter(
    (m: MessageProps) => !m?.priceStatus
  )

  const hasNothingValue =
    completedPromotion.length === 0 ? (
      <p>
        Compre a partir de R$
        {Math.min(...nextAvailablePromotion.map((p) => p.floorPrice))} e ganhe{' '}
        <strong>{nextAvailablePromotion[0].promoName}</strong>!
      </p>
    ) : null

  const hasHalfValue =
    completedPromotion.length !== 0 && nextAvailablePromotion.length !== 0 ? (
      <p>
        Você ganhou{' '}
        <strong>
          {completedPromotion.map((p) => p.promoName).join(' E ')}
        </strong>
        ! Com mais R$
        {nextAvailablePromotion[0].floorPrice -
          nextAvailablePromotion[0].cartItemPrice}{' '}
        você ganha <strong>{nextAvailablePromotion[0].promoName}</strong>!
      </p>
    ) : null

  const hasFullValue =
    nextAvailablePromotion.length === 0 ? (
      <p>
        Você ganhou{' '}
        <strong>
          {completedPromotion.map((p) => p.promoName).join(' E ')}
        </strong>
        !
      </p>
    ) : null

  return (
    <>
      {hasNothingValue}
      {hasHalfValue}
      {hasFullValue}
    </>
  )
}

const PromotionBar = ({ product, componentType }: Props) => {
  const { promotionData: promotionPayload } = usePromotionDataContext()
  const { cartData } = useCartDataContext()

  const [activePromotion, setActivePromotion] = useState(
    [] as Array<PromotionProps & { totalPrice: number }>
  )

  const [pdpMessage, setPdpMessage] = useState([] as MessageProps[])
  const [maxPrice, setMaxPrice] = useState({} as MaxPriceProps)
  const [cartMessage, setCartMessage] = useState([] as MessageProps[])

  const validateCartPromotion = (
    itensData: CartItemsProps[],
    promoPayloadData: PromotionProps[]
  ) => {
    const activePromotionsData: Array<PromotionProps & { totalPrice: number }> =
      []

    promoPayloadData.forEach((promotion: PromotionProps) => {
      validatePromotionOnCart(itensData, promotion, activePromotionsData)
    })

    setActivePromotion(activePromotionsData)
  }

  const isVisiblePromotion = async (
    promoData: PromotionProps & {
      totalPrice: number
    }
  ) => {
    return product && viewPromotionOnPage(promoData, product)
  }

  const fetchPromotionData = async () => {
    const data = []

    for (const [idx, promoData] of activePromotion.entries()) {
      data.push({
        floorPrice: promoData.totalValueFloor,
        cartItemPrice: promoData.totalPrice,
        promoName: promoData.cmsOptions.promotionName,
        promoPage: promoData.cmsOptions.promotionPage,
        priceStatus: promoData.totalPrice >= promoData.totalValueFloor,
        key: idx,
        brands: promoData.brands,
        skus: promoData.skus,
        products: promoData.products,
        collections: promoData.collections,
        categories: promoData.categories,
        // eslint-disable-next-line no-await-in-loop
        visiblePromo: await isVisiblePromotion(promoData),
      })
    }

    return data
  }

  useEffect(() => {
    const fetchData = async () => {
      const promotionData = await fetchPromotionData()
      const orderedPromotion = orderPromotionData(promotionData)
      const messageOnCart = filterPromotionByCartItemPrice(orderedPromotion)
      const messageOnPdp = filterPromotionByVisiblePromo(orderedPromotion)
      const priceMissing = calculateMissingPrice(messageOnPdp)

      setMaxPrice({
        maxPromoPrice: getMaxPromoPrice(messageOnPdp),
        missingPrice: priceMissing,
      })
      setPdpMessage(messageOnPdp)
      setCartMessage(messageOnCart)
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePromotion])

  useEffect(() => {
    cartData &&
      promotionPayload &&
      validateCartPromotion(cartData, promotionPayload)
  }, [promotionPayload, cartData])

  const hasMaxPrice =
    maxPrice?.missingPrice !== undefined &&
    maxPrice?.maxPromoPrice !== undefined

  return componentType === 'pdp' ? (
    <PdpPromotionBar
      pdpMessage={pdpMessage}
      hasMaxPrice={hasMaxPrice}
      maxPrice={maxPrice}
    />
  ) : (
    <CartPromotionBar cartMessage={cartMessage} />
  )
}

export default PromotionBar
