import axios from 'axios'
import type { WishlistType } from 'src/typings/wishlist'

const updateListWishlist = async (newList: WishlistType) => {
  await axios.patch('/api/wishlist/editWishlist', {
    wishlist: {
      id: newList?.id,
      name: newList?.name,
      productList: newList?.productList,
      isDefault: newList?.isDefault,
    },
  })
}

export default updateListWishlist
