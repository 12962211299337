import React from 'react'

import type { MessageProps } from './PromotionBar'
import { setMessageInfo } from './PromotionBar'

interface Props {
  cartMessage: MessageProps[]
}

const CartPromotionBar = ({ cartMessage }: Props) => {
  const hasCartMessage = cartMessage && cartMessage.length > 0

  return hasCartMessage ? (
    <div className="promotion__bar-wrapper promotion__bar-hidden">
      <div className="promotion__bar-container">
        <div className="promotion__bar-message">
          {setMessageInfo(cartMessage)}
        </div>
      </div>
    </div>
  ) : null
}

export default CartPromotionBar
