import type { WishlistType } from 'src/typings/wishlist'
import type { ProductSummary_ProductFragment } from '@generated/graphql'

import getDataWishlistForDataLayer from './getDataWishlistForDataLayer'
import getWishlistByProductId from './getWishlistByProductId'

const newWishlistAndDataLayer = (
  productItem: ProductSummary_ProductFragment,
  listsCopy: WishlistType[]
) => {
  const productId = productItem?.sku ?? productItem?.id
  const dataProductForDataLayer = getDataWishlistForDataLayer(productItem)
  const wishlist = getWishlistByProductId(listsCopy, productId)

  const updatedProductList = wishlist?.productList?.filter(
    (product) => product.productId !== productId
  )

  return {
    ...dataProductForDataLayer,
    updatedProductList,
    wishlist,
  }
}

export default newWishlistAndDataLayer
