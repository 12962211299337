import { useGetThumborImageData } from '@vtex/gatsby-plugin-thumbor'
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image'
import React, { useEffect, useMemo } from 'react'
import type { ThumborImageOptions } from '@vtex/gatsby-plugin-thumbor'
import type { GatsbyImageProps } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'

import './image.scss'

interface Props extends Omit<GatsbyImageProps, 'image'> {
  desktop: ThumborImageOptions
  mobile: ThumborImageOptions
  altData?: string
  setAltForEvent?: (value: string) => void
}

const DESKTOP_BREAKPOINTS = [1920, 2732]

function ImageWithArtDirection({
  mobile,
  desktop,
  setAltForEvent,
  altData,
  ...imgProps
}: Props) {
  const getImage = useGetThumborImageData()
  const { ref, inView } = useInView()

  const image = useMemo(() => {
    const mobileImage = getImage(mobile)
    const desktopImage = getImage({
      ...desktop,
      breakpoints: DESKTOP_BREAKPOINTS,
    })

    return withArtDirection(mobileImage, [
      {
        media: '(min-width: 40em)',
        image: desktopImage,
      },
    ])
  }, [desktop, getImage, mobile])

  useEffect(() => {
    if (inView && setAltForEvent && altData) {
      setAltForEvent(altData)
    }
  }, [inView, altData])

  return (
    <>
      <div ref={ref} />
      <GatsbyImage
        {...imgProps}
        image={image}
        style={{ willChange: 'unset' }}
      />
    </>
  )
}

export default ImageWithArtDirection
