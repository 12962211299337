exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-[slug]-p-tsx": () => import("./../../../src/pages/[slug]/p.tsx" /* webpackChunkName: "component---src-pages-[slug]-p-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-colecao-slug-tsx": () => import("./../../../src/pages/colecao/[...slug].tsx" /* webpackChunkName: "component---src-pages-colecao-slug-tsx" */),
  "component---src-pages-especial-presentes-quiz-tsx": () => import("./../../../src/pages/especial/presentes/quiz.tsx" /* webpackChunkName: "component---src-pages-especial-presentes-quiz-tsx" */),
  "component---src-pages-garantia-de-presente-index-tsx": () => import("./../../../src/pages/garantia-de-presente/index.tsx" /* webpackChunkName: "component---src-pages-garantia-de-presente-index-tsx" */),
  "component---src-pages-garantia-de-presente-validate-tsx": () => import("./../../../src/pages/garantia-de-presente/validate.tsx" /* webpackChunkName: "component---src-pages-garantia-de-presente-validate-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-life-lovers-tsx": () => import("./../../../src/pages/life-lovers.tsx" /* webpackChunkName: "component---src-pages-life-lovers-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-loja-slug-tsx": () => import("./../../../src/pages/loja/[...slug].tsx" /* webpackChunkName: "component---src-pages-loja-slug-tsx" */),
  "component---src-pages-lp-tsx": () => import("./../../../src/pages/lp.tsx" /* webpackChunkName: "component---src-pages-lp-tsx" */),
  "component---src-pages-nossas-lojas-tsx": () => import("./../../../src/pages/nossas-lojas.tsx" /* webpackChunkName: "component---src-pages-nossas-lojas-tsx" */),
  "component---src-pages-pesquisa-satisfacao-sac-tsx": () => import("./../../../src/pages/pesquisa-satisfacao-sac.tsx" /* webpackChunkName: "component---src-pages-pesquisa-satisfacao-sac-tsx" */),
  "component---src-pages-produto-slug-tsx": () => import("./../../../src/pages/produto/[...slug].tsx" /* webpackChunkName: "component---src-pages-produto-slug-tsx" */),
  "component---src-pages-s-tsx": () => import("./../../../src/pages/s.tsx" /* webpackChunkName: "component---src-pages-s-tsx" */),
  "component---src-pages-size-guide-index-tsx": () => import("./../../../src/pages/size-guide/index.tsx" /* webpackChunkName: "component---src-pages-size-guide-index-tsx" */),
  "component---src-pages-size-guide-measures-tsx": () => import("./../../../src/pages/size-guide/measures.tsx" /* webpackChunkName: "component---src-pages-size-guide-measures-tsx" */),
  "component---src-pages-slug-tsx": () => import("./../../../src/pages/[...slug].tsx" /* webpackChunkName: "component---src-pages-slug-tsx" */),
  "component---src-pages-wishlist-index-tsx": () => import("./../../../src/pages/wishlist/index.tsx" /* webpackChunkName: "component---src-pages-wishlist-index-tsx" */),
  "component---src-pages-wishlist-list-wishlist-id-tsx": () => import("./../../../src/pages/wishlist/list/[...wishlistId].tsx" /* webpackChunkName: "component---src-pages-wishlist-list-wishlist-id-tsx" */)
}

