import { Script } from 'gatsby'
import React from 'react'

const InsiderScript = () => {
  return (
    <Script
      key="insider"
      type="text/javascript"
      src="//vivara.api.useinsider.com/ins.js?id=10007082"
      async
    />
  )
}

export default InsiderScript
