import React, {
  useEffect,
  useState,
  createContext,
  lazy,
  Suspense,
} from 'react'
import { useUI } from 'src/sdk/ui/Provider'
import type {
  ProductSummary_ProductFragment,
  CmsInstitutionalPageConnection,
  CmsHomeConnection,
  SiteSiteMetadata,
  ServerCollectionPageQueryQuery,
  ServerProductPageQueryQuery,
} from '@generated/graphql'
import { isStorageAvailable } from 'src/utils/isStorageAvailable'
import CartSidebar from 'src/components/cart/CartSidebar'

import type { CmsSection } from './components/common/Header/Header'
import './Layout.scss'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import lazyRetry from './utils/lazyRetry'
import CookieEnablePrompt from './components/sections/CookieEnablePrompt'
import { useCampaignContext } from './contexts/campaign-context'
import UtmCampaignCart from './components/UtmCampaign/UtmCampaignCart'
import GeneralConfig from './mocks/GeneralConfig'
import ThirdPartyScripts from './components/ThirdPartyScripts'
import { CmsGlobalComponentsProvider } from './contexts/CMSGlobalComponents/cms-global-components-context'
import getDataSellerCode from './utils/getSellerCodeData'
import extractREValue from './utils/extractREValue'
import { useCart } from './sdk/cart'
import { cartGenerate } from './utils/generateCart'
import { PromotionDataProvider } from './contexts/promotion-data-context'
import { CartDataProvider } from './contexts/card-data-context'

const HeaderContainer = lazy(() =>
  lazyRetry(
    () => import('./components/common/HeaderContainer'),
    'HeaderContainer'
  )
)

const Footer = lazy(() =>
  lazyRetry(() => import('./components/common/Footer/Footer'), 'Footer')
)

const WhatsApp = lazy(() =>
  lazyRetry(() => import('./components/common/GTMScript/WhatsApp'), 'WhatsApp')
)

const SocialSellingSync = lazy(() =>
  lazyRetry(() => import('./components/socialSellingSync'), 'SocialSellingSync')
)

interface Props {
  data: LocationCms
  children?: React.ReactNode
  params?: { slug: string }
  location: { pathname: string }
  serverData?: ServerCollectionPageQueryQuery & ServerProductPageQueryQuery
}

export type LocationCms = {
  product: ProductSummary_ProductFragment
  cmsGlobalComponents?: Record<string, CmsSection[]>
  allCmsInstitutionalPage?: CmsInstitutionalPageConnection
  allCmsHome?: CmsHomeConnection
  site?: { SiteMetadata: SiteSiteMetadata }
}

export type HeaderSizeContextProps = {
  headerSize: number | null
  setHeaderSize: (number: number | null) => void
  isTelemarketingBarVisible: boolean
  setIsTelemarketingBarVisible: (isTelemarketing: boolean) => void
  isLife: boolean | null
  showSearch: boolean
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>
}

export const HeaderSizeContext = createContext<HeaderSizeContextProps | null>(
  null
)

function Layout({
  children,
  data,
  params,
  location: { pathname },
  serverData,
}: Props) {
  const { setCampaignPage } = useCampaignContext()
  const { cart: displayCart } = useUI()
  const [location, setLocation] = useState<LocationCms | null>(null)
  const [isLife, setIsLife] = useState<boolean | null>(null)
  const { id } = useCart()
  const [isTelemarketingBarVisible, setIsTelemarketingBarVisible] =
    useState(false)

  const [loadFooter, setLoadFooter] = useState(true)

  const [headerSize, setHeaderSize] = useState<number | null>(null)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const hideAboveFooter = pathname?.includes('/nossas-lojas')
  const slug = params?.slug
  const shouldUpdateInfoFromParameter =
    window?.location.pathname === '/' ||
    window?.location.pathname.endsWith('/p')

  useEffect(() => {
    const url = window?.location?.search
    const reValue = extractREValue(url)

    if (!reValue) {
      return
    }

    if (id === '') {
      cartGenerate({ reload: true })
    }

    getDataSellerCode(id, reValue)
  }, [id])

  useEffect(() => {
    const localTheme = sessionStorage?.getItem('campaignTheme')

    localTheme && setCampaignPage(localTheme === 'true')
    setLocation(data)
  }, [data])

  useEffect(() => {
    window.insider_object = window.insider_object || {}
    window.insider_object.page = {
      ...window.insider_object.page,
      type: displayCart ? 'Basket' : window.insider_object.page?.originalType,
    }
  }, [displayCart])

  useEffect(() => {
    setTimeout(() => setLoadFooter(false), 200)
  }, [pathname, slug, serverData])

  const isPDP = !!serverData?.product

  const noScriptIframeEco = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W6XP4NCM"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`

  const noScriptIframe = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WJ4J8X6"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`

  if (!isStorageAvailable()) {
    return <CookieEnablePrompt />
  }

  return (
    <>
      <ThirdPartyScripts key="ThirdPartyScripts" />
      {shouldUpdateInfoFromParameter && <SocialSellingSync />}
      <noscript dangerouslySetInnerHTML={{ __html: noScriptIframeEco }} />
      <noscript dangerouslySetInnerHTML={{ __html: noScriptIframe }} />
      {GeneralConfig.isUTMActive && <UtmCampaignCart />}
      <HeaderSizeContext.Provider
        value={{
          headerSize,
          setHeaderSize,
          isTelemarketingBarVisible,
          setIsTelemarketingBarVisible,
          isLife,
          showSearch,
          setShowSearch,
        }}
      >
        <CmsGlobalComponentsProvider
          currentCMSGlobalComponents={data?.cmsGlobalComponents}
        >
          <CartDataProvider>
            <PromotionDataProvider>
              <HeaderContainer
                isPDP={isPDP}
                location={location}
                isLife={isLife}
                setIsLife={setIsLife}
                slug={slug}
                edges={data?.allCmsInstitutionalPage?.edges}
                pathname={pathname}
                brandName={serverData?.product?.brand?.name}
                isStorageAvailable={isStorageAvailable}
              />
              <div id="layout">
                <main className={`${!isPDP && 'main-container'}`}>
                  {children}
                </main>
                {!loadFooter && (
                  <Suspense fallback={null}>
                    <Footer hideAboveFooter={hideAboveFooter} />
                  </Suspense>
                )}
                {displayCart && (
                  <Suspense fallback={null}>
                    <CartSidebar />
                  </Suspense>
                )}

                <WhatsApp />
              </div>
            </PromotionDataProvider>
          </CartDataProvider>
        </CmsGlobalComponentsProvider>
      </HeaderSizeContext.Provider>
    </>
  )
}

export default Layout
