import type { WishlistType } from 'src/typings/wishlist'

import isProductOnWishlist from './isProductOnWishlist'

const getWishlistByProductId = (
  listWishlist: WishlistType[],
  productId: string
) => {
  for (const list of listWishlist) {
    const isOnThisWishlist = isProductOnWishlist(productId, [list])

    if (isOnThisWishlist) {
      return list
    }
  }

  return null
}

export default getWishlistByProductId
