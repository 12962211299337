import type { ProductSummary_ProductFragment } from '@generated/graphql'

interface IProductInfo {
  productRefId: string
  nameCategory: string[]
  variant?: string
  index?: number
}

const postMessageWishlist = (
  productItem: ProductSummary_ProductFragment,
  productInfo: IProductInfo,
  nameEvent: string
) => {
  const { productRefId, variant, index, nameCategory } = productInfo

  window.postMessage({
    name: 'AnalyticsEvent',
    params: {
      name: nameEvent,
      params: {
        productId: productRefId,
        name: productItem?.name,
        price: productItem?.offers?.lowPrice,
        brand: productItem?.brand?.name,
        category: `Joias/${nameCategory[0]}`,
        position: index !== undefined ? index + 1 : null,
        variant: variant && variant !== productRefId ? variant : '',
      },
    },
  })
}

export default postMessageWishlist
