function extractREValue(url: string) {
  if (!url) {
    return null
  }

  const urlParams = new URLSearchParams(url)
  const reValue = urlParams?.get('RE')

  return reValue ?? ''
}

export default extractREValue
