import type { BrowserProductQueryQuery } from '@generated/graphql'
import type { ProductType } from 'src/components/product/ProductCard/ProductCardTypes'

export type InsiderWishlistProduct = {
  product: {
    id: string
    name: string
    taxonomy: string[]
    currency: string
    unit_price: number
    unit_sale_price: number
    url: string
    product_image_url: string
    custom: {
      subcategoria_sku: string
      material_sku: string | undefined
      pedra_sku: string | undefined
      colecao_sku: string | undefined
      departamento: string
      marca: string
      sku_id: string
      categoria_sku: string
      grupo_categoria_sku: string
      relogio_material_caixa: string | undefined
      relogio_material_pulseira: string | undefined
      publico_sku: string | undefined
    }
  }
  quantity: number
  subtotal: number
}

type InsiderBrowserProduct = {
  product: ProductType
  quantity: number
  subtotal: number
}

function normalizeBreadcrumbList(
  breadcrumbList:
    | {
        itemListElement: Array<{ item: string; name: string; position: number }>
      }
    | ProductType['breadcrumbList']
): string[] {
  if (Array.isArray(breadcrumbList)) {
    return breadcrumbList.map((item) => item.name)
  }

  if (breadcrumbList?.itemListElement) {
    return breadcrumbList.itemListElement.map((item) => item.name ?? '')
  }

  return []
}

const addProductToInsiderWishlistObject = (
  data: BrowserProductQueryQuery | InsiderBrowserProduct
) => {
  const specificationGroup = data.product?.isVariantOf?.additionalProperty
  const categories = normalizeBreadcrumbList(data.product?.breadcrumbList)

  window.insider_object = window.insider_object || {}
  if (!window.insider_object.wishlist) {
    window.insider_object.wishlist = {
      id: '',
      currency: 'BRL',
      total: 0,
      line_items: [],
    }
  }

  window.insider_object.wishlist.line_items.push({
    product: {
      id: data.product?.sku,
      name: data.product?.name,
      taxonomy: categories,
      currency: 'BRL',
      unit_price: data.product?.offers?.offers?.[0]?.listPrice.toFixed(2),
      unit_sale_price: data.product?.offers?.offers?.[0]?.price.toFixed(2),
      url: `/${data.product?.slug}`,
      product_image_url: data.product?.image?.[0]?.url,
      custom: {
        subcategoria_sku:
          data.product?.breadcrumbList?.itemListElement?.[1]?.name ?? '',
        material_sku:
          specificationGroup?.find(
            (property) => property.name === 'Material'
          ) ?? 'SEM MATERIAL',
        pedra_sku:
          specificationGroup?.find((property) => property.name === 'Pedras') ??
          'SEM PEDRAS',
        colecao_sku: specificationGroup?.find(
          (property) => property.name === 'Coleção'
        ),
        departamento:
          data.product.breadcrumbList?.itemListElement?.[0]?.name ?? '',
        marca: data.product?.brand?.name,
        sku_id: data.product?.sku,
        categoria_sku: categories[1],
        grupo_categoria_sku: categories[2],
        relogio_material_caixa:
          specificationGroup?.find(
            (property) => property.name === 'Material do Vidro'
          ) ?? 'SEM MATERIAL DO VIDRO',
        relogio_material_pulseira:
          specificationGroup?.find(
            (property) => property.name === 'Material da Pulseira'
          ) ?? 'SEM MATERIAL DA PULSEIRA',
        publico_sku:
          specificationGroup?.find(
            (property) => property.name === 'Sugestão'
          ) ?? 'SEM SUGESTÃO',
      },
    },
    quantity: 1,
    subtotal: data.product?.offers?.offers?.[0]?.price.toFixed(2),
  })

  const total = window.insider_object.wishlist.line_items.reduce(
    (acc: number, item: InsiderWishlistProduct) => acc + Number(item.subtotal),
    0
  )

  window.insider_object.wishlist.total = total?.toFixed(2)
}

export default addProductToInsiderWishlistObject
