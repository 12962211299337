import type {
  IMoveWishlistProductUtil,
  WishlistType,
} from 'src/typings/wishlist'
import updateListWishlist from 'src/utils/wishlist/updateListWishlist'

async function moveWishlistProductUtil({
  productIndex,
  wishlistIndex,
  setLists,
  currentWishlist,
  deleteProduct,
}: IMoveWishlistProductUtil) {
  const product = currentWishlist.productList[productIndex]
  const listDeleted: WishlistType[] | boolean = await deleteProduct(
    product?.productId
  )

  listDeleted?.[wishlistIndex]?.productList?.push(product)

  setLists(listDeleted)

  await updateListWishlist(listDeleted?.[wishlistIndex])
}

export default moveWishlistProductUtil
