import type { WishlistType } from 'src/typings/wishlist'

const isProductOnWishlist = (id: string, wishlistArray: WishlistType[]) => {
  const allProductsOnWishlist = wishlistArray
    ?.map((list) => list.productList)
    ?.flat()

  return allProductsOnWishlist?.some((product) => product.productId === id)
}

export default isProductOnWishlist
