import type {
  IChangeWishlistProductSizeUtil,
  WishlistType,
} from 'src/typings/wishlist'
import { deepObjectCopy } from 'src/utils'
import getWishlistIndexById from 'src/utils/wishlist/getWishlistIndexById'
import updateListWishlist from 'src/utils/wishlist/updateListWishlist'

async function changeWishlistProductSizeUtil({
  productIndex,
  newSize,
  lists,
  setLists,
  currentWishlist,
  productSku,
}: IChangeWishlistProductSizeUtil) {
  const listsCopy = deepObjectCopy(lists) as WishlistType[]
  const product = currentWishlist?.productList[productIndex]

  product.productSize = newSize
  product.productId = productSku

  const currentWishlistIndex = getWishlistIndexById(currentWishlist, lists)

  listsCopy[currentWishlistIndex].productList[productIndex] = product
  await updateListWishlist(listsCopy[currentWishlistIndex])
  setLists(listsCopy)
}

export default changeWishlistProductSizeUtil
