import axios from 'axios'
import type { CartItem } from 'src/sdk/cart'
import { cartGenerate } from 'src/utils/generateCart'

import getAllCustomAppFromOrderForm from './getAllCustomAppsFromOrderForm'

interface IOrderKpiCustomData {
  products: CartItem[] | null
  appName?: string
  cartId?: string
}

interface IHandleOrderKpiDataProps extends IOrderKpiCustomData {
  marketingTag?: string
}

export interface ICustomApps {
  fields: {
    products: string
  }
  id: string
}

let waitUpdateCustomData = false

export const changeWaitUpdateCustomData = (value: boolean) => {
  waitUpdateCustomData = value
}

export const waitCustomData = async () => {
  await new Promise((resolve) => {
    if (!waitUpdateCustomData) {
      resolve(true)

      return true
    }

    const interval = setInterval(() => {
      if (!waitUpdateCustomData) {
        clearInterval(interval)
        resolve(true)
      }
    }, 200)

    return false
  })
}

const getCustomAppFromOrderForm = async (
  cartId: string | undefined,
  searchCustomData: string | undefined
) => {
  const customApps = await getAllCustomAppFromOrderForm(cartId)

  return customApps?.find((app: ICustomApps) => app?.id === searchCustomData)
}

const newListProductsInCustomApps = async ({
  cartId,
  appName,
  products,
}: IOrderKpiCustomData) => {
  const customApp = await getCustomAppFromOrderForm(cartId, appName)
  const productsIdCurrent = customApp?.fields?.products?.split(',') ?? []
  const productsId = products?.map((product) => product.id) ?? []
  const newProductsIds = [...productsIdCurrent, ...productsId]

  return newProductsIds?.filter(
    (productId, index) => newProductsIds?.indexOf(productId) === index
  )
}

const setOrderKpiCustomData = async ({
  products,
  appName,
  cartId,
}: IOrderKpiCustomData) => {
  try {
    waitUpdateCustomData = true

    const uniqProductsIds = await newListProductsInCustomApps({
      cartId,
      appName,
      products,
    })

    const body = {
      orderformID: cartId,
      appName,
      fieldName: 'products',
      fieldValue: `${uniqProductsIds}`,
    }

    await axios.post('/api/setCustomData', body)
  } finally {
    waitUpdateCustomData = false
  }
}

export const setOrderKpiMarketingTag = async (
  marketingTag: string,
  cartId?: string,
  removeTag = false
) => {
  cartId = cartId ?? (await cartGenerate({ reload: false }))

  try {
    await axios.post('/api/setMarketingData', {
      marketingTags: [marketingTag],
      orderformID: cartId,
      removeTag,
    })
  } catch (error) {
    console.error(error)
  }
}

export const handleOrderKpiData = async ({
  products,
  appName,
  cartId,
  marketingTag,
}: IHandleOrderKpiDataProps) => {
  try {
    if (appName && marketingTag) {
      cartId = cartId || (await cartGenerate({ reload: false }))

      await Promise.all([
        setOrderKpiCustomData({ cartId, products, appName }),
        setOrderKpiMarketingTag(marketingTag, cartId),
      ])

      return true
    }
  } catch (error) {
    console.error('error', error)
  }

  return false
}
