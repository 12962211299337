import type { ProductSummary_ProductFragment } from '@generated/graphql'
import type { ProductPropertieType } from 'src/components/ui/QuantitySelector/QuantitySelector'

const getDataWishlistForDataLayer = (
  productItem: ProductSummary_ProductFragment
) => {
  const variant = productItem?.gtin
  const nameCategory = productItem?.name?.split(' ')
  const productRefId: string =
    productItem?.isVariantOf?.additionalProperty?.filter(
      (propertie: ProductPropertieType) => propertie.name === 'Cód'
    )?.[0]?.value

  return {
    variant,
    productRefId,
    nameCategory,
  }
}

export default getDataWishlistForDataLayer
