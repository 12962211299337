export function isStorageAvailable() {
  if (typeof window === 'undefined') {
    return true
  }

  if (!navigator?.cookieEnabled) {
    return false
  }

  try {
    const testKey = '__testStorage__'

    window.localStorage.setItem(testKey, 'testValue')
    window.localStorage.removeItem(testKey)

    window.sessionStorage.setItem(testKey, 'testValue')
    window.sessionStorage.removeItem(testKey)

    return true
  } catch (error) {
    return false
  }
}
