import type { ComponentType } from 'react'
import type { Scalars } from '@generated/graphql'

import wait from './wait'

type DynamicImportType = () => Promise<{
  default: ComponentType<Scalars['ObjectOrString']>
}>

const refreshedLazy = (name: string) => {
  const hasRefreshed = JSON.parse(
    window?.sessionStorage?.getItem(`retry-lazy-refreshed-${name}`) ?? 'false'
  )

  if (!hasRefreshed) {
    window?.sessionStorage?.setItem(`retry-lazy-refreshed-${name}`, 'true')
    window?.location?.reload()

    return true
  }

  return false
}

const lazyRetry = (
  componentImport: DynamicImportType,
  name: string,
  retries = 2
): Promise<{
  default: Scalars['ObjectOrString']
}> => {
  const INTERVAL_RETRY = 2000

  return new Promise((resolve, reject) => {
    componentImport()
      .then((component) => {
        resolve(component)

        window?.sessionStorage?.removeItem(`retry-lazy-refreshed-${name}`)
      })
      .catch(async (error: Error) => {
        let isRetries = false

        if (retries) {
          isRetries = true

          await wait(INTERVAL_RETRY)

          lazyRetry(componentImport, name, retries - 1)
        }

        if (!isRetries && !refreshedLazy(name)) {
          reject(error)
        }
      })
  })
}

export default lazyRetry
